
.react-rainbow-admin-followers_section {
    padding: 16px 40px 24px 40px;
}

@media (max-width: 800px) {
    .react-rainbow-admin-followers_view-port {
        padding: 16px 16px 24px 16px;
    }

    .react-rainbow-admin-followers_section {
        flex-direction: column;
    }

    .react-rainbow-admin-followers_card:not(:last-child) {
        margin: 0 0 8px 0;
    }
}