.react-rainbow-admin-header-pages_title {
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #061c3f;
}

.react-rainbow-admin-header-pages_description {
    font-size: 12px;
    color: #a4a7b5;
}
