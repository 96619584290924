.react-rainbow-admin-component-cards_card {
    width: 13rem;
    margin: 0.8rem 1.5rem 0.8rem 0;
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #061c3f;
}

.react-rainbow-admin-component-cards_card-image {
    padding: 1rem;
    height: 5.5rem;
    margin: auto;
    display: flex;
}

@media (max-width: 600px) {
    .react-rainbow-admin-component-cards_link {
        width: 100%;
    }

    .react-rainbow-admin-component-cards_card {
        width: 100%;
        margin-right: 0;
    }

    .react-rainbow-admin-component-cards_card-image {
        height: 8rem;
    }
}
