.react-rainbow-admin-pages_container {
    padding: 16px 40px;
}

.react-rainbow-admin-pages_body {
    display: flex;
    flex-wrap: wrap;
}

.react-rainbow-admin-pages_card {
    width: 200px;
    font-size: 16px;
    font-weight: 500;
    color: #061c3f;
}

.react-rainbow-admin-pages_card-image {
    padding: 1rem;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-rainbow-admin-pages_anchor {
    margin-top: 24px;
    margin-right: 24px;
}

.react-rainbow-admin-pages_anchor:hover {
    text-decoration: none;
}

.react-rainbow-admin-pages_anchor:last-child {
    margin-right: 0;
}

@media (max-width: 800px) {
    .react-rainbow-admin-pages_container {
        padding: 16px;
    }
    .react-rainbow-admin-pages_anchor, .react-rainbow-admin-pages_card {
        margin-right: 8px;
    }
    .react-rainbow-admin-pages_card-image {
        height: 8rem;
    }
}

@media (max-width: 600px) {
    .react-rainbow-admin-pages_anchor, .react-rainbow-admin-pages_card {
        width: 100%;
        margin-right: 0;
    }
}
