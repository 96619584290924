.react-rainbow-admin-applications-cards_container {
    position: relative;
    margin: 0 16px 20px 16px;
    width: 260px;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.react-rainbow-admin-applications-cards_example-link:hover, .react-rainbow-admin-applications-cards_example-link:focus {
    text-decoration: none;
}

.react-rainbow-admin-applications-cards {
    width: 260px;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.react-rainbow-admin-applications-cards:hover {
    box-shadow: 0 4px 8px 0 #e3e5ed;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.react-rainbow-admin-applications-cards_content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.react-rainbow-admin-applications-cards_header {
    font-size: 16px;
    margin: 0;
    color: #061c3f;
    text-align: justify;
    align-self: flex-start;
}

.react-rainbow-admin-applications-cards_description {
    font-size: 14px;
    line-height: 1.5;
    color: #576574;
    align-self: flex-start;
    text-align: justify;
    overflow: hidden;
    max-height: 4rem;
}

.react-rainbow-admin-applications-cards_description::before {
    content:'...';
    position: absolute;
    bottom: 0.75rem;
    right: 0.375rem;
}

.react-rainbow-admin-applications-cards_description::after {
    content: '';
    position: absolute;
    width: 4rem;
    height: 1.5rem;
    background-color: white;
}

.react-rainbow-admin-applications-cards_description:hover {
    max-height: 100%;
}

.react-rainbow-admin-applications-cards_description:hover::before {
    content: '';
}

.react-rainbow-admin-applications-cards_img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    margin-top: 0.75rem;
}

.react-rainbow-admin-applications-cards_img-container {
    margin: 1.2rem 0.75rem;
}

.react-rainbow-admin-applications-cards_img {
    height: 80px;
}

.react-rainbow-admin-applications-cards_github-link {
    position: absolute;
    top: 16px;
    right: 16px;
}

.react-rainbow-admin-applications-cards_github-link:hover > svg {
    fill: #009ACF;
}

@media (max-width: 600px) {
    .react-rainbow-admin-applications-cards {
        width: 100%;
    }

    .react-rainbow-admin-applications-cards_container {
        margin: 0 0 20px 0;
        width: 100%;
    }

    .react-rainbow-admin-applications-cards_description {
        max-height: 100%;
    }
    .react-rainbow-admin-applications-cards_description::before {
        content:'';
    }
}
