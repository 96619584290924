.react-rainbow-admin-app_sidebar-container {
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 68px);
    margin-top: 68px;
    background-color: #fff;
    z-index: 99999;
    padding-bottom: 14px;
    padding-top: 14px;
}

.react-rainbow-admin-app_sidebar {
    width: 88px;
    height: 100%;
    border-right: 1px solid #f4f6f9;
    overflow-y: auto;
    flex-grow: 1;
}

.react-rainbow-admin-app_router-container {
    padding-top: 68px;
    padding-left: 88px;
    background-color: #f4f6f9;
    min-height: 100vh;
}

.react-rainbow-admin-app_sidebar-item {
    margin-bottom: 16px;
}

.react-rainbow-admin-app_sidebar-back-button-container {
    display: none;
}
.download-link {
    float: right;
    margin-top: -21px;
    margin-right: 10px;
}
@media (max-width: 600px) {
    .react-rainbow-admin-app_sidebar-container {
        height: 100vh;
        margin-top: 0;
        transform: translateX(0%);
        transition: transform 230ms linear, opacity 230ms linear;
    }

    .react-rainbow-admin-app_router-container {
        padding-top: 116px;
        padding-left: 0;
    }

    .react-rainbow-admin-app_sidebar-container--sidebar-hidden {
        transform: translateX(-100%);
        transition: transform 250ms linear, opacity 250ms linear;
    }

    .react-rainbow-admin-app_sidebar-back-button-container {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-grow: 2;
        align-items: flex-end;
        padding-top: 12px;
    }

    .react-rainbow-admin-app_sidebar-back-button-icon {
        color: #000;
    }

    .react-rainbow-admin-app_backdrop {
        display: unset;
        width: 100vw;
        height: 100vh;
        background-color: rgba(82, 95, 127, 0.4);
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
    }
}

/* highlight effect */
@-o-keyframes highlightBg {
    0% {
        background: #fff
    }
    50% {
        background: #fffb00
    }
    to {
        background: #fff
    }
}
@-webkit-keyframes highlightBg {
    0% {
        background: #fff
    }
    50% {
        background: #fffb00
    }
    to {
        background: #fff
    }
}

@keyframes highlightBg {
    0% {
        background: #fff
    }
    50% {
        background: #fffb00
    }
    to {
        background: #fff
    }
}

.highlight {
/*     box-shadow: 0 0 0 2px #FF4081; */
  -webkit-animation: highlightBg 1s linear;
  -o-animation: highlightBg 1s linear;
  animation: highlightBg 1s linear;
  -webkit-animation-iteration-count: 3;
  -o-animation-iteration-count: 3;
  animation-iteration-count: 3
}
