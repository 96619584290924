.react-rainbow-admin-users_header-container {
    padding: 16px 40px 0;
}

.react-rainbow-admin-users_header {
    margin-top: 14px;
}

.react-rainbow-admin-users_cards-container {
    padding: 16px 40px 24px 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.react-rainbow-admin-full_card {
    width: 100%;
    padding-bottom: 16px;
}

.react-rainbow-admin-full_chart {
    margin: 0 20px;
    height: 300px;
}

.react-rainbow-admin-users_card {
    width: 48%;
    padding-bottom: 16px;
}


.react-rainbow-admin-users_chart {
    margin: 0 20px;
}

.react-rainbow-admin-users_card-title {
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #a4a7b5;
    text-transform: uppercase;
    margin-bottom: 8px;
    text-align: center;
}

.react-rainbow-admin-users_tab-set {
    padding-left: 40px;
    background-color: #f4f6f9;
}

.react-rainbow-admin-users_tab-content {
    height: max-content;
    padding-top: 24px;
    background-color: white;
    padding-bottom: 24px;
}

.react-rainbow-admin-users_current-status {
    display: flex;
    align-items: center;
}

.react-rainbow-admin-users_current-status_value {
    margin-left: 8px;
}

.react-rainbow-admin-users_table-edit-icon {
    border: 1px solid #e3e5ed;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-rainbow-admin-users_badge {
    background-color: #1de9b6;
}

.react-rainbow-admin-users_user-id-cell-container {
    display: flex;
    align-items: center;
}

.react-rainbow-admin-users_user-id-cell {
    margin-left: 8px;
    font-weight: 400;
}

.react-rainbow-admin-users_user-id-cell-container:hover {
    color: #01b6f5;
}

@media (max-width: 800px) {
    .react-rainbow-admin-users_header-container {
        padding: 16px;
    }
    .react-rainbow-admin-users_cards-container {
        flex-direction: column;
    }
    .react-rainbow-admin-users_card {
        width: 100%;
        margin-bottom: 16px;
    }
    .react-rainbow-admin-users_tab-set {
        padding-left: 24px;
    }
}
