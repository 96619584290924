.react-rainbow-admin-orders_header-container {
    padding: 16px 40px 0;
}

.react-rainbow-admin-orders_header {
    margin-top: 14px;
}

.react-rainbow-admin-orders_cards-container {
    padding: 16px 40px 24px 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.react-rainbow-admin-orders_card {
    width: 48%;
}

.react-rainbow-admin-orders_chart-title {
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #a4a7b5;
    text-transform: uppercase;
    margin-bottom: 8px;
    text-align: center;
}

.react-rainbow-admin-orders_chart {
    margin: 0 20px 16px 20px;
}

.react-rainbow-admin-orders_table-container {
    height: max-content;
    background-color: white;
    padding-bottom: 24px;
}

.react-rainbow-admin-orders_table {
    border-top: 1px solid #e3e5ed;
    margin-top: 8px;
}

.react-rainbow-admin-orders_table-edit-icon {
    border: 1px solid #e3e5ed;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-rainbow-admin-orders_badge {
    background-color: #1de9b6;
}
