.react-rainbow-admin-followers_view-port {
    padding: 16px 40px 24px 40px;
}

.react-rainbow-admin-followers_header {
    margin-top: 14px;
}

.react-rainbow-admin-followers_tile-facebook {
    background-color: #3c5997;
}

.react-rainbow-admin-followers_tile-twitter {
    background-color: #00b0f3;
}

.react-rainbow-admin-followers_tile-linkedin {
    background-color: #0077b5;
}

.react-rainbow-admin-followers_card {
    width: 100%;
    flex-wrap: wrap;
}

.react-rainbow-admin-followers_card:not(:last-child) {
    margin-right: 24px;
}

.react-rainbow-admin-followers_card-chart--title {
    font-family: Lato;
    font-size: 18px;
    line-height: 1.67;
    letter-spacing: 1.1px;
}

.react-rainbow-admin-followers_badge--success {
    color: #FFF;
    background-color: #1de9b6;
    align-self: flex-start;
}

.react-rainbow-admin-followers_badge--error {
    color: #FFF;
    background-color: #fe4849;
    align-self: flex-start;
}

.react-rainbow-admin-followers_chart {
    height: 100px;
}

.react-rainbow-admin-followers_chart-title {
    letter-spacing: 0.2px;
    text-transform: uppercase;
}

.react-rainbow-admin-followers_tile-icon {
    height: 42px;
    width: 42px;
}

.react-rainbow-admin-followers_chart {
    height: 130px;
}

@media (max-width: 800px) {
    .react-rainbow-admin-followers_view-port {
        padding: 16px 16px 24px 16px;
    }

    .react-rainbow-admin-followers_section {
        flex-direction: column;
    }

    .react-rainbow-admin-followers_card:not(:last-child) {
        margin: 0 0 8px 0;
    }
}
