.react-rainbow-admin-charts_container {
    padding: 16px 40px;
}
.react-rainbow-admin-charts_section {
    align-items: stretch;
}
.react-rainbow-admin-charts_card {
    width: 100%;
    flex-wrap: wrap;
}

.react-rainbow-admin-charts_chart{
    height: 240px;
}

.react-rainbow-admin-charts_card:not(:last-child){
    margin-right: 24px;
}

.react-rainbow-admin-charts_header-title-container {
    display: flex;
    align-items: center;
}

.react-rainbow-admin-charts_header-icon {
    flex-shrink: 0;
}

.react-rainbow-admin-charts_header-icon > svg {
    width: 22px;
    height: 22px;
}

.react-rainbow-admin-charts_header-title {
    color:#576574;
    font-size: 1.25rem;
}

.react-rainbow-admin-chart_button {
    line-height: 1.8rem;
}

.react-rainbow-admin-charts_chart-title {
    letter-spacing: 0.2px;
    text-transform: uppercase;
}

@media (max-width: 800px){
    .react-rainbow-admin-charts_container {
        padding: 16px 16px;
    }
    .react-rainbow-admin-charts_section {
        flex-direction: column;
    }
    .react-rainbow-admin-charts_card:not(:last-child){
        margin: 0 0 24px 0;
    }
    .react-rainbow-admin-charts_header {
        flex-direction: column;
        align-items: center;
    }
}
