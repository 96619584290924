.react-rainbow-admin-applications_view-port {
    padding: 16px 24px 24px 24px;
}

.react-rainbow-admin-applications_header-page {
    padding: 0px 16px 0px 16px;
}

.react-rainbow-admin-applications_container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
}

@media (max-width: 800px) {
    .react-rainbow-admin-applications_header-page {
        padding: 0;
    }
}
