.react-rainbow-admin-applications-cards_container {
    position: relative;
    margin: 0 16px 20px 16px;
    width: 260px;
    -webkit-transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.react-rainbow-admin-applications-cards_example-link:hover, .react-rainbow-admin-applications-cards_example-link:focus {
    text-decoration: none;
}

.react-rainbow-admin-applications-cards {
    width: 260px;
    -webkit-transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.react-rainbow-admin-applications-cards:hover {
    box-shadow: 0 4px 8px 0 #e3e5ed;
    -webkit-transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.react-rainbow-admin-applications-cards_content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.react-rainbow-admin-applications-cards_header {
    font-size: 16px;
    margin: 0;
    color: #061c3f;
    text-align: justify;
    align-self: flex-start;
}

.react-rainbow-admin-applications-cards_description {
    font-size: 14px;
    line-height: 1.5;
    color: #576574;
    align-self: flex-start;
    text-align: justify;
    overflow: hidden;
    max-height: 4rem;
}

.react-rainbow-admin-applications-cards_description::before {
    content:'...';
    position: absolute;
    bottom: 0.75rem;
    right: 0.375rem;
}

.react-rainbow-admin-applications-cards_description::after {
    content: '';
    position: absolute;
    width: 4rem;
    height: 1.5rem;
    background-color: white;
}

.react-rainbow-admin-applications-cards_description:hover {
    max-height: 100%;
}

.react-rainbow-admin-applications-cards_description:hover::before {
    content: '';
}

.react-rainbow-admin-applications-cards_img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    margin-top: 0.75rem;
}

.react-rainbow-admin-applications-cards_img-container {
    margin: 1.2rem 0.75rem;
}

.react-rainbow-admin-applications-cards_img {
    height: 80px;
}

.react-rainbow-admin-applications-cards_github-link {
    position: absolute;
    top: 16px;
    right: 16px;
}

.react-rainbow-admin-applications-cards_github-link:hover > svg {
    fill: #009ACF;
}

@media (max-width: 600px) {
    .react-rainbow-admin-applications-cards {
        width: 100%;
    }

    .react-rainbow-admin-applications-cards_container {
        margin: 0 0 20px 0;
        width: 100%;
    }

    .react-rainbow-admin-applications-cards_description {
        max-height: 100%;
    }
    .react-rainbow-admin-applications-cards_description::before {
        content:'';
    }
}

.react-rainbow-admin-header-pages_title {
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #061c3f;
}

.react-rainbow-admin-header-pages_description {
    font-size: 12px;
    color: #a4a7b5;
}

.react-rainbow-admin-applications_view-port {
    padding: 16px 24px 24px 24px;
}

.react-rainbow-admin-applications_header-page {
    padding: 0px 16px 0px 16px;
}

.react-rainbow-admin-applications_container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
}

@media (max-width: 800px) {
    .react-rainbow-admin-applications_header-page {
        padding: 0;
    }
}

.react-rainbow-admin-charts_container {
    padding: 16px 40px;
}
.react-rainbow-admin-charts_section {
    align-items: stretch;
}
.react-rainbow-admin-charts_card {
    width: 100%;
    flex-wrap: wrap;
}

.react-rainbow-admin-charts_chart{
    height: 240px;
}

.react-rainbow-admin-charts_card:not(:last-child){
    margin-right: 24px;
}

.react-rainbow-admin-charts_header-title-container {
    display: flex;
    align-items: center;
}

.react-rainbow-admin-charts_header-icon {
    flex-shrink: 0;
}

.react-rainbow-admin-charts_header-icon > svg {
    width: 22px;
    height: 22px;
}

.react-rainbow-admin-charts_header-title {
    color:#576574;
    font-size: 1.25rem;
}

.react-rainbow-admin-chart_button {
    line-height: 1.8rem;
}

.react-rainbow-admin-charts_chart-title {
    letter-spacing: 0.2px;
    text-transform: uppercase;
}

@media (max-width: 800px){
    .react-rainbow-admin-charts_container {
        padding: 16px 16px;
    }
    .react-rainbow-admin-charts_section {
        flex-direction: column;
    }
    .react-rainbow-admin-charts_card:not(:last-child){
        margin: 0 0 24px 0;
    }
    .react-rainbow-admin-charts_header {
        flex-direction: column;
        align-items: center;
    }
}

.react-rainbow-admin-dashboard_view-port {
    padding: 16px 40px 24px 40px;
}

.react-rainbow-admin-dashboard_card {
    width: 100%;
    flex-wrap: wrap;
}

.react-rainbow-admin-dashboard_card:not(:last-child) {
    margin-right: 24px;
}

.react-rainbow-admin-dashboard_card--content {
    font-size: 28px;
    line-height: 1.07;
    letter-spacing: 1.8px;
}

.react-rainbow-admin-dashboard_badge--success {
    background-color: #1de9b6 !important;
    align-self: flex-start;
}

.react-rainbow-admin-dashboard_badge--error {
    background-color: #fe4849 !important;
    align-self: flex-start;
}

.react-rainbow-admin-dashboard_chart-title {
    letter-spacing: 0.2px;
    text-transform: uppercase;
}

.react-rainbow-admin-dashboard_avatar {
    flex-shrink: 0;
}

.react-rainbow-admin-dashboard_divider {
    border-bottom: solid 1px #e3e5ed;
}

.react-rainbow-admin-dashboard_list-card--footer-link {
    font-size: 13px;
}

.react-rainbow-admin-dashboard_list-card--footer-link:hover,
.react-rainbow-admin-dashboard_list-card--footer-link:focus,
.react-rainbow-admin-dashboard_list-card--footer-link:active,
.react-rainbow-admin-dashboard_list-card--footer-link:visited {
    color: #009acf;
}

.react-rainbow-admin-dashboard_card-users--user {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #fe4849;
    margin-right: 4px;
    margin-bottom: 4px;
}

.react-rainbow-admin-dashboard_card-users--user-active {
    background-color: #1de9b6;
}

.react-rainbow-admin-dashboard_card-message--message-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.react-rainbow-admin-dashboard_tile-icon {
    height: 37px;
    width: 37px;
}

@media (max-width: 800px) {
    .react-rainbow-admin-dashboard_view-port {
        padding: 16px 16px 24px 16px;
    }

    .react-rainbow-admin-dashboard_section {
        flex-direction: column;
    }

    .react-rainbow-admin-dashboard_card:not(:last-child) {
        margin: 0 0 8px 0;
    }
}

.react-rainbow-admin-component-cards_card {
    width: 13rem;
    margin: 0.8rem 1.5rem 0.8rem 0;
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #061c3f;
}

.react-rainbow-admin-component-cards_card-image {
    padding: 1rem;
    height: 5.5rem;
    margin: auto;
    display: flex;
}

@media (max-width: 600px) {
    .react-rainbow-admin-component-cards_link {
        width: 100%;
    }

    .react-rainbow-admin-component-cards_card {
        width: 100%;
        margin-right: 0;
    }

    .react-rainbow-admin-component-cards_card-image {
        height: 8rem;
    }
}

.react-rainbow-admin-components_view-port {
    padding: 16px 40px 24px 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.react-rainbow-admin-components_page-header {
    align-self: flex-start;
}

.react-rainbow-admin-components_title {
    margin-top: 1.25rem;
    margin-bottom: 1rem;
    font-family: Lato;
    font-size: 1.25rem;
    font-weight: 300;
    letter-spacing: 0.3px;
    color: #01b6f5;
}

.react-rainbow-admin-components_search {
    width: 70%;
}

.react-rainbow-admin-components_sections-container {
    display: flex;
    flex-direction: column;
}

.react-rainbow-admin-components_cards-section {
    display: flex;
    flex-wrap: wrap;
}

.react-rainbow-admin-components_cards-section_title {
    font-family: Lato;
    font-size: 20px;
    font-weight: 300;
    color: #576574;
    display: block;
    padding-top: 40px;
}

@media (max-width: 600px) {
    .react-rainbow-admin-components_title {
        font-size: 1.25rem;
        font-weight: 300;
        letter-spacing: 0.01875rem;
    }

    .react-rainbow-admin-components_search {
        width: 100%;
    }

    .react-rainbow-admin-components_cards-section_title {
        text-align: center;
    }
}

.react-rainbow-admin-pages_container {
    padding: 16px 40px;
}

.react-rainbow-admin-pages_body {
    display: flex;
    flex-wrap: wrap;
}

.react-rainbow-admin-pages_card {
    width: 200px;
    font-size: 16px;
    font-weight: 500;
    color: #061c3f;
}

.react-rainbow-admin-pages_card-image {
    padding: 1rem;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-rainbow-admin-pages_anchor {
    margin-top: 24px;
    margin-right: 24px;
}

.react-rainbow-admin-pages_anchor:hover {
    text-decoration: none;
}

.react-rainbow-admin-pages_anchor:last-child {
    margin-right: 0;
}

@media (max-width: 800px) {
    .react-rainbow-admin-pages_container {
        padding: 16px;
    }
    .react-rainbow-admin-pages_anchor, .react-rainbow-admin-pages_card {
        margin-right: 8px;
    }
    .react-rainbow-admin-pages_card-image {
        height: 8rem;
    }
}

@media (max-width: 600px) {
    .react-rainbow-admin-pages_anchor, .react-rainbow-admin-pages_card {
        width: 100%;
        margin-right: 0;
    }
}


.react-rainbow-admin-table-pagination {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-top: 1px solid #e3e5ed;
    border-bottom: 1px solid #e3e5ed;
    margin-bottom: 24px;
}



.react-rainbow-admin-tile {
    width: 100%;
    flex-wrap: wrap;
}

.react-rainbow-admin-tile:not(:last-child) {
    margin-right: 24px;
}

.react-rainbow-admin-tile_title {
    letter-spacing: 0.3px;
    color: #a4a7b5;
}

.react-rainbow-admin-tile_title--inverse {
    color: #e3e5ed;
}

.react-rainbow-admin-tile_content {
    font-size: 28px;
    line-height: 1.07;
    letter-spacing: 1.8px;
    color: #061c3f;
    
}

.react-rainbow-admin-tile_content--inverse {
    color: #FFF;
}

@media (max-width: 800px) {
    .react-rainbow-admin-tile:not(:last-child) {
        margin: 0 0 8px 0;
    }
}



.react-rainbow-admin-followers_section {
    padding: 16px 40px 24px 40px;
}

@media (max-width: 800px) {
    .react-rainbow-admin-followers_view-port {
        padding: 16px 16px 24px 16px;
    }

    .react-rainbow-admin-followers_section {
        flex-direction: column;
    }

    .react-rainbow-admin-followers_card:not(:last-child) {
        margin: 0 0 8px 0;
    }
}
.content-wrapper {
    margin-top: 20px;
}
div.date-range {
    display: flex;
    align-content: center;
}
div.date-range {
    display: flex;
    align-content: center;
}
div.date-range {
    display: flex;
    align-content: center;
}
div.date-range {
    display: flex;
    align-content: center;
}
.react-rainbow-admin-user-cell_container {
    display: flex;
    align-items: center;
}

.react-rainbow-admin-user-cell_name {
    margin-left: 8px;
    font-weight: 400;
}

.react-rainbow-admin-user-cell_container:hover {
    color: #01b6f5;
}

.react-rainbow-admin-users_header-container {
    padding: 16px 40px 0;
}

.react-rainbow-admin-users_header {
    margin-top: 14px;
}

.react-rainbow-admin-users_cards-container {
    padding: 16px 40px 24px 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.react-rainbow-admin-full_card {
    width: 100%;
    padding-bottom: 16px;
}

.react-rainbow-admin-full_chart {
    margin: 0 20px;
    height: 300px;
}

.react-rainbow-admin-users_card {
    width: 48%;
    padding-bottom: 16px;
}


.react-rainbow-admin-users_chart {
    margin: 0 20px;
}

.react-rainbow-admin-users_card-title {
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #a4a7b5;
    text-transform: uppercase;
    margin-bottom: 8px;
    text-align: center;
}

.react-rainbow-admin-users_tab-set {
    padding-left: 40px;
    background-color: #f4f6f9;
}

.react-rainbow-admin-users_tab-content {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    padding-top: 24px;
    background-color: white;
    padding-bottom: 24px;
}

.react-rainbow-admin-users_current-status {
    display: flex;
    align-items: center;
}

.react-rainbow-admin-users_current-status_value {
    margin-left: 8px;
}

.react-rainbow-admin-users_table-edit-icon {
    border: 1px solid #e3e5ed;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-rainbow-admin-users_badge {
    background-color: #1de9b6;
}

.react-rainbow-admin-users_user-id-cell-container {
    display: flex;
    align-items: center;
}

.react-rainbow-admin-users_user-id-cell {
    margin-left: 8px;
    font-weight: 400;
}

.react-rainbow-admin-users_user-id-cell-container:hover {
    color: #01b6f5;
}

@media (max-width: 800px) {
    .react-rainbow-admin-users_header-container {
        padding: 16px;
    }
    .react-rainbow-admin-users_cards-container {
        flex-direction: column;
    }
    .react-rainbow-admin-users_card {
        width: 100%;
        margin-bottom: 16px;
    }
    .react-rainbow-admin-users_tab-set {
        padding-left: 24px;
    }
}

.react-rainbow-admin-orders-table--processing {
    color: #a0a0bc;
}

.react-rainbow-admin-orders-table--verified {
    color: #1de9b6;
}

.react-rainbow-admin-orders-table--expired {
    color: #fe4849;
}

.react-rainbow-admin-order-id-cell_container {
    display: flex;
    align-items: center;
}

.react-rainbow-admin-order-id-cell_name {
    margin-left: 8px;
    font-weight: 400;
}

.react-rainbow-admin-order-id-cell_container:hover {
    color: #01b6f5;
}

.react-rainbow-admin-user-detail_wrapper {
    overflow-y: auto;
}

.react-rainbow-admin-user-detail_breadcrumbs {
    padding: 20px 30px 0 30px;
}

.react-rainbow-admin-user-detail_cards-container {
    padding: 0 30px 30px 30px;
}

.react-rainbow-admin-user-detail_card {
    margin-top: 16px;
}

.react-rainbow-admin-user-detail_card-content {
    padding: 0.75rem 1rem;
}

.react-rainbow-admin-user-detail_icon {
    height: 25px;
    width: 25px;
    border-radius: 100%;
    background-color: #3b5998;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}

.react-rainbow-admin-user-detail_icon > svg {
    margin-right: 0 !important;
    color: white;
    fill: white;
}

.react-rainbow-admin-user-detail_user-name {
    color: #061c3f;
    font-size: 1.25rem;
    margin-right: 12px;
}

.react-rainbow-admin-user-detail_user-status {
    color: #1ad1a3;
}

.react-rainbow-admin-user-detail_vertical-divider {
    background-color: #e3e5ed;
    width: 1px;
    margin: 0 16px;
    height: 38px;
}

.react-rainbow-admin-user-detail_user-identity {
    display: flex;
    flex-wrap: wrap;
    border-top: 0.0625rem solid #e3e5ed;
}

.react-rainbow-admin-user-detail_user-identity_field {
    width: 50%;
    margin-bottom: 8px;
}

.react-rainbow-admin-user-detail_field {
    display: flex;
    flex-direction: column;
}

.react-rainbow-admin-user-detail_field-label {
    font-size: 0.75rem;
    color: #576574;
}

.react-rainbow-admin-user-detail_field-value {
    font-size: 1rem;
    color: #061c3f;
}

.react-rainbow-admin-user-detail_orders {
    color: #061c3f;
    font-size: 20px;
    padding: 0 30px;
}

.react-rainbow-admin-user-details_table-container {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    background-color: white;
    padding-bottom: 24px;
}

.react-rainbow-admin-user-details_table {
    border-top: 1px solid #e3e5ed;
    margin-top: 8px;
}

.react-rainbow-admin-orders_header-container {
    padding: 16px 40px 0;
}

.react-rainbow-admin-orders_header {
    margin-top: 14px;
}

.react-rainbow-admin-orders_cards-container {
    padding: 16px 40px 24px 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.react-rainbow-admin-orders_card {
    width: 48%;
}

.react-rainbow-admin-orders_chart-title {
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #a4a7b5;
    text-transform: uppercase;
    margin-bottom: 8px;
    text-align: center;
}

.react-rainbow-admin-orders_chart {
    margin: 0 20px 16px 20px;
}

.react-rainbow-admin-orders_table-container {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    background-color: white;
    padding-bottom: 24px;
}

.react-rainbow-admin-orders_table {
    border-top: 1px solid #e3e5ed;
    margin-top: 8px;
}

.react-rainbow-admin-orders_table-edit-icon {
    border: 1px solid #e3e5ed;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-rainbow-admin-orders_badge {
    background-color: #1de9b6;
}

.react-rainbow-admin-followers_view-port {
    padding: 16px 40px 24px 40px;
}

.react-rainbow-admin-followers_header {
    margin-top: 14px;
}

.react-rainbow-admin-followers_tile-facebook {
    background-color: #3c5997;
}

.react-rainbow-admin-followers_tile-twitter {
    background-color: #00b0f3;
}

.react-rainbow-admin-followers_tile-linkedin {
    background-color: #0077b5;
}

.react-rainbow-admin-followers_card {
    width: 100%;
    flex-wrap: wrap;
}

.react-rainbow-admin-followers_card:not(:last-child) {
    margin-right: 24px;
}

.react-rainbow-admin-followers_card-chart--title {
    font-family: Lato;
    font-size: 18px;
    line-height: 1.67;
    letter-spacing: 1.1px;
}

.react-rainbow-admin-followers_badge--success {
    color: #FFF;
    background-color: #1de9b6;
    align-self: flex-start;
}

.react-rainbow-admin-followers_badge--error {
    color: #FFF;
    background-color: #fe4849;
    align-self: flex-start;
}

.react-rainbow-admin-followers_chart {
    height: 100px;
}

.react-rainbow-admin-followers_chart-title {
    letter-spacing: 0.2px;
    text-transform: uppercase;
}

.react-rainbow-admin-followers_tile-icon {
    height: 42px;
    width: 42px;
}

.react-rainbow-admin-followers_chart {
    height: 130px;
}

@media (max-width: 800px) {
    .react-rainbow-admin-followers_view-port {
        padding: 16px 16px 24px 16px;
    }

    .react-rainbow-admin-followers_section {
        flex-direction: column;
    }

    .react-rainbow-admin-followers_card:not(:last-child) {
        margin: 0 0 8px 0;
    }
}

.react-rainbow-admin-messages {
    display: flex;
    min-height: calc(100vh - 68px);
}

.react-rainbow-admin-messages_contacts-container {
    width: 30%;
    background-color: #f4f6f9;
}

.react-rainbow-admin-messages_contacts-search {
    padding: 0.75rem 0.625rem;
    border-bottom: 1px solid #eceef6;
}

.react-rainbow-admin-messages_contacts {
    padding: 0 0.625rem;
}

.react-rainbow-admin-messages_contact {
    display: flex;
    padding: 0.5rem;
    margin: 0.75rem 0;
    width: 100%;
    height: 3.25rem;
    border-radius: 100px;
}

.react-rainbow-admin-messages_contact:hover {
    background-color: #eceef6;
}

.react-rainbow-admin-messages_contact--active {
    background-color: #eceef6;
}

.react-rainbow-admin-messages_contact-avatar {
    flex-shrink: 0;
}

.react-rainbow-admin-messages_contact--online {
    width: 5px;
    height: 5px;
    background-color: #1de9b6;
    border-radius: 100%;
    position: absolute;
    margin-top: 18px;
}

.react-rainbow-admin-messages_contact-content {
    width: 88%;
    margin-left: 0.75rem;
    pointer-events: none;
}

.react-rainbow-admin-messages_contact-name {
    font-size: 0.875rem;
    line-height: 1.29;
    letter-spacing: 0.5px;
    color: #061c3f;
}

.react-rainbow-admin-messages_contact-time {
    font-size: 0.75rem;
    display: flex;
    justify-content: flex-end;
    letter-spacing: 0.5px;
    text-align: right;
    color: #a4a7b5;
}

.react-rainbow-admin-messages_contact-message {
    line-height: 1.4;
    color: #576574;
    font-size: 0.625rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 98%;
}

.react-rainbow-admin-messages_body {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-color: #eff1f7;
    padding: 1rem 2.25rem;
    position: relative;
}

.react-rainbow-admin-messages_input-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border-radius: 20px;
    padding: 0.5rem;
}

.react-rainbow-admin-messages_input-options {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0 1rem;
}

.react-rainbow-admin-messages_body-messages {
    margin-bottom: 3rem;
    width: 100%;
}

.react-rainbow-admin-messages_message {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    position: relative;
}

.react-rainbow-admin-messages_message:not(:first-child) {
    margin-top: 1rem;
}

.react-rainbow-admin-messages_message-avatar {
    flex-shrink: 0;
}

.react-rainbow-admin-messages_message-text-contact {
    display: flex;
    align-items: flex-start;
    border-radius: 0.625rem;
    padding: 0.625rem;
    font-size: 1rem;
    margin: -0.625rem 0 0.25rem 0.75rem;
    background-color: #081832;
    color: #fff;
}

.react-rainbow-admin-messages_message-text-contact:before {
    width: 0;
    height: 0;
    content:"";
    top:0.875rem;
    right: 1.375rem;
    margin-right: -0.875rem;
    position:relative;
    border-style: solid;
    border-width: 0.75rem 1rem 0 0;
    border-color: transparent #081832 transparent transparent;
    -webkit-transform: rotateZ(-20deg);
            transform: rotateZ(-20deg);
}

.react-rainbow-admin-messages_message-text-user {
    display: flex;
    align-items: flex-start;
    border-radius: 0.625rem;
    padding: 0.625rem;
    margin: -0.625rem 0.75rem 0.25rem 0;
    font-size: 1rem;
    background-color: #fff;
}

.react-rainbow-admin-messages_message-text-user:after {
    width: 0;
    height: 0;
    content:"";
    top:0.875rem;
    left: 1.375rem;
    margin-left: -0.875rem;
    position:relative;
    border-style: solid;
    border-width: 0 1rem 0.75rem 0;
    border-color: transparent transparent #fff transparent;
    -webkit-transform: rotateZ(20deg);
            transform: rotateZ(20deg);
}

.react-rainbow-admin-messages_message--sent {
    position: absolute;
    right: 3.15rem;
    bottom: -0.875rem;
    display: flex;
    align-items: center;
}

.react-rainbow-admin-messages_message--sent svg {
    margin-left: 0.25rem;
}

.react-rainbow-admin-messages_message--sent-date {
    position: absolute;
    left: 3.75rem;
    bottom: -0.875rem;
}

.react-rainbow-admin-messages_message--checked g {
    fill: #01b6f5;
    stroke: #01b6f5;
}

.react-rainbow-admin-messages_input .rainbow-input_icon-container .rainbow-input_icon {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 100%;
    background-color: #01b6f5;
    margin-top: 0.25rem;
    margin-right: -0.25rem;
}

.react-rainbow-admin-messages_body-selected-contact {
    position: absolute;
    top: 1rem;
    left: 2.25rem;
    display: flex;
}

.react-rainbow-admin-messages_body--divider {
    width: 100%;
    border-bottom: 1px solid #e6e9ef;
    position: absolute;
    top: 4rem;
}

.react-rainbow-admin-messages_body-selected-contact-content {
    margin-left: 0.75rem;
    pointer-events: none;
}

.react-rainbow-admin-messages_body-selected-contact-name {
    font-size: 0.875rem;
    line-height: 1.29;
    letter-spacing: 0.5px;
    color: #061c3f;
}

.react-rainbow-admin-messages_body-selected-contact--status {
    line-height: 1.4;
    color: #576574;
    font-size: 0.625rem;
}

.react-rainbow-admin-forms_container {
    padding: 16px 40px;
}

.react-rainbow-admin-forms_section {
    display: flex;
    align-items: stretch;
}

.react-rainbow-admin-forms_section:last-child  {
    width: 75%;
}

.react-rainbow-admin-forms_card{
    width: 100%;
    flex-wrap: wrap;
}

.react-rainbow-admin-forms_card:not(:last-child) {
    margin-right: 48px;
}

.react-rainbow-admin-forms_header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.react-rainbow-admin-forms_header h1 {
    font-size: 1.2rem;
    color: #061c3f;
}

.react-rainbow-admin-forms_header p {
    font-weight: 300;
    color: #576574;
    line-height: 1.71;
}

.react-rainbow-admin-forms_header p:nth-child(2){
    font-weight: normal;
    color: #01b6f5;
    margin-left: 3px;
}

.react-rainbow-admin-forms_logo {
    width: 24px;
    height: 24px;
}

.rainbow-rainbow-forms_inputs-container {
    display: flex;
    flex-direction: column;
    padding: 0 24px 24px;
    width: 100%;
}

.rainbow-rainbow-forms_inputs-field {
    flex:1 1;
}

.rainbow-rainbow-forms_inputs-field:not(:last-child) {
    margin-right: 24px;
}

.rainbow-rainbow-forms_forgot {
    text-align: center;
    color: #01b6f5;
    font-size: 1rem;
}

.rainbow-rainbow-forms_contact-details-avatar {
    background-color: #f4f6f9;
    border-radius: 32px; 
}

.rainbow-rainbow-forms_contact-details > .rainbow-card_header-container {
    padding-bottom: 10px;
    border-bottom: 1px solid #e3e5ed;
}

.rainbow-rainbow-forms_contact-details > .rainbow-card_footer {
    text-align: right;
    padding-right: 30px;
}

@media (max-width: 800px){
    .react-rainbow-admin-forms_container {
        padding: 16px;
    }

    .react-rainbow-admin-forms_section {
        flex-direction: column;
        padding-top: 0;
    }

    .rainbow-rainbow-forms_button {
        width: 100%;
    }

    .react-rainbow-admin-forms_section:last-child  {
        width: 100%;
    }

    .react-rainbow-admin-forms_card {
        margin-top: 24px;
    }
    
    .rainbow-rainbow-forms_inputs-container > .rainbow-flex {
        flex-direction: column;
    }

    .rainbow-rainbow-forms_inputs-field {
        margin: 0 0 18px 0 !important;
    }
}

.react-rainbow-admin-coming-soon_container {
    justify-content: center;
    display: flex;
    align-items: center;
    align-content: center;
    min-height: calc(100vh - 68px);
}

.react-rainbow-admin-coming-soon_image {
    width: 100%;
    height: 80vh;
    background-image: url(/static/media/coming-soon.9d4e6c2d.svg);
    background-size: cover;
    background-position: center;
}

@media (max-width: 1024px) {
    .react-rainbow-admin-coming-soon_image {
        height: 70vh;
    }
}

@media (max-width: 800px) {
    .react-rainbow-admin-coming-soon_image {
        height: 60vh;
    }
}

@media (max-width: 600px) {
    .react-rainbow-admin-coming-soon_container {
        min-height: calc(100vh - 116px);
    }
    .react-rainbow-admin-coming-soon_image {
        height: 50vh;
    }
}

.react-rainbow-admin_header {
    top: 0;
    left: 0;
    height: 68px;
    width: 100vw;
    z-index: 10000;
    border-bottom: 1px solid #f4f6f9;
}

.react-rainbow-admin_header-logo {
    width: 36px;
    height: 36px;
}

.react-rainbow-admin_header-search {
    width: 280px;
}

.react-rainbow-admin_header-actions {
    justify-content: flex-end;
    flex-grow: 1;
}

.react-rainbow-admin_header--notification-icon-container {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: #e3e5ed;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.react-rainbow-admin_header--notification-icon {
    height: 12px;
}

.react-rainbow-admin_header-hamburger-button {
    display: none;
}

@media (max-width: 600px) {
    .react-rainbow-admin_header {
        height: 124px;
        padding: 16px 16px 0 16px;
        align-items: flex-start;
    }

    .react-rainbow-admin_header-search {
        margin: 0;
        flex-shrink: 0;
        position: absolute;
        bottom: 12px;
        width: calc(100% - 80px);
    }

    .react-rainbow-admin_header-actions {
        align-items: flex-start;
    }

    .react-rainbow-admin_header-hamburger-button {
        display: inherit;
        height: 40px;
        width: 40px;
        margin-left: 16px;
    }

    .react-rainbow-admin_header-button-notification {
        margin: 0 8px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 12px;
        right: 4px;
    }
}

.react-rainbow-admin-app_sidebar-container {
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 68px);
    margin-top: 68px;
    background-color: #fff;
    z-index: 99999;
    padding-bottom: 14px;
    padding-top: 14px;
}

.react-rainbow-admin-app_sidebar {
    width: 88px;
    height: 100%;
    border-right: 1px solid #f4f6f9;
    overflow-y: auto;
    flex-grow: 1;
}

.react-rainbow-admin-app_router-container {
    padding-top: 68px;
    padding-left: 88px;
    background-color: #f4f6f9;
    min-height: 100vh;
}

.react-rainbow-admin-app_sidebar-item {
    margin-bottom: 16px;
}

.react-rainbow-admin-app_sidebar-back-button-container {
    display: none;
}
.download-link {
    float: right;
    margin-top: -21px;
    margin-right: 10px;
}
@media (max-width: 600px) {
    .react-rainbow-admin-app_sidebar-container {
        height: 100vh;
        margin-top: 0;
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
        -webkit-transition: opacity 230ms linear, -webkit-transform 230ms linear;
        transition: opacity 230ms linear, -webkit-transform 230ms linear;
        transition: transform 230ms linear, opacity 230ms linear;
        transition: transform 230ms linear, opacity 230ms linear, -webkit-transform 230ms linear;
    }

    .react-rainbow-admin-app_router-container {
        padding-top: 116px;
        padding-left: 0;
    }

    .react-rainbow-admin-app_sidebar-container--sidebar-hidden {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        -webkit-transition: opacity 250ms linear, -webkit-transform 250ms linear;
        transition: opacity 250ms linear, -webkit-transform 250ms linear;
        transition: transform 250ms linear, opacity 250ms linear;
        transition: transform 250ms linear, opacity 250ms linear, -webkit-transform 250ms linear;
    }

    .react-rainbow-admin-app_sidebar-back-button-container {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-grow: 2;
        align-items: flex-end;
        padding-top: 12px;
    }

    .react-rainbow-admin-app_sidebar-back-button-icon {
        color: #000;
    }

    .react-rainbow-admin-app_backdrop {
        display: unset;
        width: 100vw;
        height: 100vh;
        background-color: rgba(82, 95, 127, 0.4);
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
    }
}

/* highlight effect */
@-webkit-keyframes highlightBg {
    0% {
        background: #fff
    }
    50% {
        background: #fffb00
    }
    to {
        background: #fff
    }
}

@keyframes highlightBg {
    0% {
        background: #fff
    }
    50% {
        background: #fffb00
    }
    to {
        background: #fff
    }
}

.highlight {
/*     box-shadow: 0 0 0 2px #FF4081; */
  -webkit-animation: highlightBg 1s linear;
  animation: highlightBg 1s linear;
  -webkit-animation-iteration-count: 3;
  animation-iteration-count: 3
}

