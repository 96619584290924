.react-rainbow-admin-coming-soon_container {
    justify-content: center;
    display: flex;
    align-items: center;
    align-content: center;
    min-height: calc(100vh - 68px);
}

.react-rainbow-admin-coming-soon_image {
    width: 100%;
    height: 80vh;
    background-image: url(./coming-soon.svg);
    background-size: cover;
    background-position: center;
}

@media (max-width: 1024px) {
    .react-rainbow-admin-coming-soon_image {
        height: 70vh;
    }
}

@media (max-width: 800px) {
    .react-rainbow-admin-coming-soon_image {
        height: 60vh;
    }
}

@media (max-width: 600px) {
    .react-rainbow-admin-coming-soon_container {
        min-height: calc(100vh - 116px);
    }
    .react-rainbow-admin-coming-soon_image {
        height: 50vh;
    }
}
